import React, { Component } from 'react';
import "./styles/signIn.css"

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uname: '',
            email: '',
            password: '',
            confirmPassword: ''
        };
    }

    // Funktion zur Registrierung
    signup = async (event) => {
        event.preventDefault(); // Verhindert die Standardaktion des Formulars

        const { uname, email, password, confirmPassword } = this.state;

        // Überprüfen, ob die Passwörter übereinstimmen
        if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        try {
            const response = await fetch(`https://www.vue.cloud-lm.de/api/setUser`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    pwd: password,
                    uname: uname
                })
            });

            const response1 = await fetch(`https://www.vue.cloud-lm.de/api/getUser/${email}`, { method: 'GET' });
            const user = await response1.json();

            document.cookie = `authToken=${user['id']}; path=/; max-age=86400`;
            localStorage.setItem('id', user['id']); // 1 Tag gültig

            // Eingabefelder zurücksetzen
            this.setState({ uname: '', email: '', password: '', confirmPassword: '' });
            window.location.reload(); // Seite neu laden nach erfolgreicher Registrierung
        } catch (err) {
            alert("Email already exists!");
            console.log(err);
        }
    };

    // Aktualisieren des Zustands bei Eingabeänderungen
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { uname, email, password, confirmPassword } = this.state;

        return (
            <div>
                <h2 className="header">Please sign up</h2>
                <form onSubmit={this.signup}>
                    <div className="input">
                        <label htmlFor="uname">Name: </label>
                        <input
                            id="uname"
                            type="text"
                            name="uname"
                            value={uname}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="email">Email: </label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="password">Password: </label>
                        <input
                            id="password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="confirmPassword">Confirm Password: </label>
                        <input
                            id="confirmPassword"
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="addButton">Sign Up</button>
                </form>
            </div>
        );
    }
}

export default SignUp;
