import React, { Component } from 'react';
import Header from './components/header';
import Task from './components/task';
import AddTask from './components/addTask';
import Authenticate from './components/authenticate';
import "./App.css"

class App extends Component {
  // Initialisiere den State
  state = {
    tasks: [], // speichert die Aufgaben
    permissionGranted: false, // Status der Benachrichtigungsberechtigung
    notificationMessage: 'Enable Notifications to not miss your tasks!',
  };

  // Aufgaben abrufen und Berechtigungen prüfen, sobald die Komponente gemountet wurde
  async componentDidMount() {
    if (localStorage.getItem('id')) {
      await this.refreshTasks();
      this.checkNotificationPermission();
    }
  }

  // Aufgaben von der API abrufen
  async getTasks(id) {
    const response = await fetch(`https://www.vue.cloud-lm.de/api/getTasks/${id}`, { method: 'GET' });
    const tasksData = await response.json();
    return tasksData;
  }

  // Aufgaben aktualisieren
  refreshTasks = async () => {
    const tasksData = await this.getTasks(localStorage.getItem('id'));
    this.setState({ tasks: tasksData });
  };

  // Benachrichtigungen anfordern
  requestPermissions = async () => {
    if (!("Notification" in window)) {
      alert("Dieser Browser unterstützt keine Desktop-Benachrichtigungen");
      console.log("Notifications not supported by browser");
      return;
    }
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      this.setState({ permissionGranted: true });
      await this.newsubHandler();
    } else {
      console.log("Permissions not granted");
    }
  }

  // Benachrichtigungsberechtigung überprüfen
  checkNotificationPermission = async () => {
    if (Notification.permission === "granted") {
      this.setState({ permissionGranted: true });
      await this.newsubHandler();
    }
  }

  // Neue Subscription-Handler
  newsubHandler = async () => {
    console.log("_______________________________________________________");
    console.log("Welcome to the New SubHandler!");

    if(!await navigator.serviceWorker.getRegistration()){
      console.log("No service-worker registered");
      return;
    }
    let situation = 0;
    let permission = false;
    let localSub = false;
    let localAndDbSub = false;

    console.log("--Step 1: Check Notification Permissions:");
    if (Notification.permission !== "granted") {
      console.log("Permissions not granted");
      localStorage.removeItem('sub');
    } else {
      permission = true;
      console.log("Permissions Granted");
    }

    console.log("--Step 2: Check for Local Sub-Item:");
    if (permission && localStorage.getItem('sub')) {
      localSub = true;
      console.log("Found local Sub-Item!");
      console.log(localStorage.getItem('sub'));
    } else {
      localSub = false;
      console.log("No local Sub-Item found.");
    }

    if (permission && localSub) {
      console.log("--Step 3: Compare local Sub-Item with Database Sub-Items:");
      // Get sub from Database
      const response = await fetch(`https://www.vue.cloud-lm.de/api/getSubs`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: localStorage.getItem('id')
        })
      });

      const dbSubs = await response.json();
      const storedSub = JSON.parse(localStorage.getItem('sub'));

      console.log("Fetched from Database: ");
      console.log(dbSubs);
      // Compare all DB results with local stored Sub
      for (const sub of dbSubs) {
        sub.sub = JSON.parse(sub.sub);
        console.log("Comparing from Database: ");
        console.log(sub.sub);
        console.log("Type of sub.sub:", typeof sub.sub);
        console.log("With from Local Storage: ");
        console.log(storedSub);
        console.log("Type of storedSub:", typeof storedSub);

        const isEndpointEqual = sub.sub.endpoint === storedSub.endpoint;
        const isP256dhEqual = sub.sub.keys.p256dh === storedSub.keys.p256dh;
        const isAuthEqual = sub.sub.keys.auth === storedSub.keys.auth;

        console.log(`Endpoint comparison: ${isEndpointEqual}`);
        console.log(`p256dh Key comparison: ${isP256dhEqual}`);
        console.log(`auth Key comparison: ${isAuthEqual}`);

        if (isEndpointEqual && isP256dhEqual && isAuthEqual) {
          console.log("Subscription match found!");
          localAndDbSub = true;
        }
      }
    }

    if (permission && localSub && localAndDbSub) {
      situation = 1;
    } else if (permission && localSub && !localAndDbSub) {
      situation = 2;
    } else if (permission && !localSub) {
      situation = 3;
    }

    switch (situation) {
      case 1:
        // Everything is fine, nothing to do
        console.log("Case 1");
        break;
      case 2:
        // Local Sub needs to be uploaded to Database
        await this.saveToDatabase(localStorage.getItem('sub'));
        console.log("Case 2");
        await this.newsubHandler();
        break;
      case 3:
        // New Sub has to be generated and must be stored in Local Storage and Database
        await this.generateAndSaveNewSub();
        console.log("Case 3");
        await this.newsubHandler();
        break;
      default:
        console.log("Default");
    }
  }

  // Subscription in der Datenbank speichern
  saveToDatabase = async (sub) => {
    const response = await fetch(`https://www.vue.cloud-lm.de/api/setSub`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id: localStorage.getItem('id'), // Temporäre feste ID direkt hier verwenden
        sub: sub // sicherstellen, dass sub als Objekt gespeichert wird
      })
    });
    console.log("Saved Subscription to Database", response);
  }

  // Neue Subscription generieren und speichern
  generateAndSaveNewSub = async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      console.error("Service Worker not registered");
      return;
    }
    let subscription = await registration.pushManager.getSubscription();
    if (!subscription) {
      // Erstelle eine neue Subscription, falls keine existiert
      const applicationServerKey = this.urlBase64ToUint8Array("BDE7iRm5f_lcT88EW3ert7cJpHgRkuKlaAbeCosZhLRzfBO1aSz8oXOqqQwj5BTuDT1c51DiNvVySnQWmlGCq64");
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      });
      localStorage.setItem("sub", JSON.stringify(subscription));
    } else {
      localStorage.setItem("sub", JSON.stringify(subscription));
    }
  }

  // Hilfsfunktion zum Umwandeln von Base64-String in Uint8Array
  urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  render() {
    const { tasks, permissionGranted, notificationMessage } = this.state;

    return (
        <div>
          <Authenticate />
          <Header />
          {!permissionGranted && (
              <div className="notifications">
                <p>{notificationMessage}</p>
                <div className="button-container">
                  <button className="reqButton" onClick={this.requestPermissions}>Enable Notifications</button>
                </div>
              </div>
          )}
          <div className="task-list">
            {tasks.map(task => (
                <Task key={task.tid} tid={task.tid} title={task.title} date={task.date} time={task.time} />
            ))}
          </div>
          <AddTask />
        </div>
    );
  }
}

export default App;
