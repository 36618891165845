import React, { Component } from 'react';
import "./styles/addTask.css"

class AddTask extends Component {
    constructor(props) {
        super(props);
        this.dialogRef = React.createRef(); // Referenz für das <dialog> Element
        this.state = {
            title: '',
            date: '',
            time: '',
        };
    }

    // Modal öffnen
    openModal = () => {
        this.dialogRef.current.showModal(); // Verwende showModal auf der Referenz
    };

    // Modal schließen
    closeModal = () => {
        this.dialogRef.current.close(); // Verwende close auf der Referenz
    };

    // Formular-Änderungen im State speichern
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    // Aufgabe hinzufügen
    addTask = async (e) => {
        e.preventDefault(); // Verhindere Seiten-Reload beim Absenden des Formulars

        const { title, date, time } = this.state;

        if (title && date && time) {
            const response = await fetch(`https://www.vue.cloud-lm.de/api/setTask`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    uid: localStorage.getItem('id'), // Nutzer-ID
                    title: title,
                    date: date,
                    time: time,
                    timestamp: Math.floor(new Date(`${date} ${time}`).getTime() / 1000), // Berechne den Timestamp
                })
            });

            if (response.ok) {
                this.setState({ title: '', date: '', time: '' }); // Setze das Formular zurück
                this.closeModal(); // Schließe das Modal
                window.location.reload(); // Seite neu laden, um die neuen Aufgaben anzuzeigen
            } else {
                console.error('Fehler beim Hinzufügen der Aufgabe:', response.statusText);
            }
        } else {
            console.error('Bitte füllen Sie alle Felder aus.');
        }
    };

    render() {
        const { title, date, time } = this.state;

        return (
            <div>
                {/* Button zum Öffnen des Modals */}
                <button className="openButton" onClick={this.openModal}>+</button>

                {/* Modal */}
                <dialog className="popup" ref={this.dialogRef}>
                    <button className="closeButton" onClick={this.closeModal}>x</button>
                    <h2 className="header">New Task</h2>

                    {/* Formular */}
                    <form onSubmit={this.addTask}>
                        <div className="input">
                            <label htmlFor="title">Task: </label>
                            <input
                                className="inputField"
                                id="title"
                                name="title"
                                type="text"
                                value={title}
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className="input">
                            <label htmlFor="date">Date: </label>
                            <input
                                className="inputField"
                                id="date"
                                name="date"
                                type="date"
                                value={date}
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className="input">
                            <label htmlFor="time">Time: </label>
                            <input
                                className="inputField"
                                id="time"
                                name="time"
                                type="time"
                                value={time}
                                onChange={this.handleChange}
                            />
                        </div>

                        <button type="submit" className="addButton">Add</button>
                    </form>
                </dialog>
            </div>
        );
    }
}

export default AddTask;
