import React, { Component } from 'react';
import "./styles/header.css";

class Header extends Component {
    // Logout-Funktion innerhalb der Komponente definiert
    logout = () => {
        // Lösche das Cookie
        document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';

        // Lösche den localStorage
        localStorage.removeItem('id');

        // Seite neu laden
        window.location.reload();
    };

    render() {
        return (
            <header>
                <img className="logo" src='/logo.png' alt='fehler'/>

                <div className="icon-text-wrapper">
                    <img className="fwlogo" src="/logo192.png" alt="Icon" />
                    <h3>Built with react</h3>
                </div>

                {/* Button mit Funktionsaufruf */}
                <button
                    onClick={this.logout} className="logout-button">Logout</button>
            </header>
        );
    }
}

export default Header;

