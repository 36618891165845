import React, { Component } from 'react';
import "./styles/signIn.css"

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }

    // Funktion zur Authentifizierung
    signin = async (event) => {
        event.preventDefault(); // Verhindert die Standardaktion des Formulars

        const { email, password } = this.state;

        if (email && password) {
            const response = await fetch(`https://www.vue.cloud-lm.de/api/authenticate`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    pwd: password
                })
            });

            if (response.ok) {
                const userId = await response.text();

                // Cookie setzen
                document.cookie = `authToken=${userId}; path=/; max-age=86400`;
                localStorage.setItem('id', userId); // 1 Tag gültig
                window.location.reload(); // Seite neu laden nach erfolgreicher Anmeldung
            } else {
                console.log('Authentication failed');
            }

            // Eingabefelder zurücksetzen
            this.setState({ email: '', password: '' });
        }
    };

    // Aktualisieren des Zustands bei Eingabeänderungen
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password } = this.state;

        return (
            <div>
                <h2 className="header">Please sign in</h2>
                <form onSubmit={this.signin}>
                    <div className="input">
                        <label htmlFor="email">Email: </label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="password">Password: </label>
                        <input
                            id="password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={this.handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="addButton">sign in</button>
                </form>
            </div>
        );
    }
}

export default SignIn;
