import React, { Component } from 'react';
import SignIn from './signIn'; // Importiere die SignIn-Komponente
import SignUp from './signUp'; // Importiere die SignUp-Komponente
import "./styles/authenticate.css"

class Authenticate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignin: true,
            authenticated: false,
            dialogVisible: false
        };
    }

    componentDidMount() {
        const authToken = this.checkLoginCookie();
        if (authToken) {
            this.setState({ authenticated: true }); // Nutzer ist eingeloggt
        } else {
            this.showDialog(); // Dialog anzeigen, wenn nicht eingeloggt
        }
    }

    // Funktion zum Überprüfen des Cookies
    checkLoginCookie = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('authToken='));
        return cookieValue ? cookieValue.split('=')[1] : null;
    };

    // Funktion zum Anzeigen des Dialogfelds
    showDialog = () => {
        this.setState({ dialogVisible: true }, () => {
            const dialog = document.querySelector('.popup');
            if (dialog) {
                dialog.showModal();  // Zeigt das Dialogfeld an
            }
        });
    };

    // Funktion zum Schließen des Dialogfelds
    closeDialog = () => {
        this.setState({ dialogVisible: false });
    };

    // Funktion zum Wechseln zwischen Signin und Signup
    toggleForm = () => {
        this.setState(prevState => ({ isSignin: !prevState.isSignin }));
    };

    render() {
        const { isSignin, dialogVisible } = this.state;

        return (
            <div>
                {dialogVisible && (
                    <dialog className="popup">
                        <div>
                            {isSignin ? <SignIn/> : <SignUp/>}
                        </div>
                        <div className="content">
                            <p className="info-text">
                                {isSignin ? 'Need a new account?' : 'Already have an account?'}
                            </p>
                            <button className="link" onClick={this.toggleForm}>
                                {isSignin ? 'sign up' : 'sign in'}
                            </button>
                            <br />
                        </div>
                    </dialog>
                )}
            </div>
        );
    }
}

export default Authenticate;
